import { computed, createApp, ref } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import ClickOutsideEvent from './utils/ClickOutsideEvent'
import type { Role } from './types/UserInterface'
import Swal from '@/utils/SweetAlert'
import mitt from 'mitt'
import type { MovieWithMediaType, PersonWithMediaType, TVWithMediaType } from './types/tmdb'
import { onIdle } from './utils/OnIdle'

const app = createApp(App)
app.directive('click-outside', ClickOutsideEvent)
app.config.globalProperties.$api = 'https://api.mozisarok.hu'

const token = ref<string | undefined>(localStorage.getItem('token') || undefined)
const role = ref<Role>({ id: 0, role: 'default', formatted: 'Látogató' })

app.config.globalProperties.$token = computed({
  get: () => {
    return token.value
  },
  set: (value: string | undefined) => {
    if (value) {
      localStorage.setItem('token', value)
    } else {
      localStorage.removeItem('token')
    }

    token.value = value
    updateUserRole()
  }
})

app.config.globalProperties.$user = computed(() => {
  if (token.value) {
    const t = JSON.parse(atob(token.value.split('.')[1]))

    if (t.exp < Date.now() / 1000) {
      app.config.globalProperties.$token.value = undefined
    } else {
      validateToken()
      return t
    }
  }

  return undefined
})

app.config.globalProperties.$role = computed({
  get: () => role.value,
  set: (value: Role) => (role.value = value)
})

const updateUserRole = () => onIdle(() => {
  app.config.globalProperties.$role.value = { id: 1, name: 'default', formatted: 'Látogató' }

  if (token.value) {
    axios
      .get(`${app.config.globalProperties.$api}/user/role`, {
        headers: {
          Authorization: `Bearer ${token.value}`
        }
      })
      .then((r) => {
        app.config.globalProperties.$role.value = r.data
      })
      .catch()
  }
})

const validateToken = () => onIdle(() => {
  if (!token.value) return

  axios
    .get(`${app.config.globalProperties.$api}/auth/validate`, {
      headers: {
        Authorization: `Bearer ${token.value}`
      }
    })
    .catch(() => {
      app.config.globalProperties.$token.value = undefined
    })
})

app.config.globalProperties.$transparent = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
app.config.globalProperties.$imageURL = 'https://image.tmdb.org/t/p/'
app.config.globalProperties.$trending = ref([])

const emitter = mitt()
app.config.globalProperties.$emitter = emitter

if (token.value) {
  updateUserRole()
}

//load home page data
axios.get(`${app.config.globalProperties.$api}/database/trending`, {}).then((r) => {
  let date = new Date()

  app.config.globalProperties.$trending.value = r.data.results.filter((m: TVWithMediaType | MovieWithMediaType | PersonWithMediaType) => {
    switch (m.media_type) {
      case 'movie':
        date = new Date(m.release_date)
        m.release_date = m.release_date?.slice(0, 4)
        return m.original_language === 'hu' || (m.title !== m.original_title && date < new Date())
      case 'tv':
        date = new Date(m.first_air_date)
        m.first_air_date = m.first_air_date?.slice(0, 4)
        return m.original_language === 'hu' || (m.name !== m.original_name && date < new Date())
      default:
        return false
    }
  })
})

app.provide('global', app.config.globalProperties)

app.use(router).use(VueAxios, axios).use(Swal).mount('#app')
